<template>
  <div>
    <template v-if="isStatesLoading">
      <v-row class="justify-center ma-0 pa-0">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
    </template>
    <template v-else>
        <AutomationWarnings :template="'corporate'"></AutomationWarnings>
        <div class="mr-6 mt-4">
          <AutomationHeader v-if="automationInfo.name || automationInfo.corporate_description" :automation-info="automationInfo" :template="'corporate'"></AutomationHeader>
          <AutomationAccordian v-if="workflows.length" :workflows="workflows" :selectedAutomationType="automationInfo" :template="'corporate'"></AutomationAccordian>
          <hb-empty-state v-else-if="workflows.length === 0" :message="`No delinquency automation is available for that company.`" :showBtn="false"></hb-empty-state>
        </div>
    </template>
  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import AutomationAccordian from "./AutomationAccordian.vue";
import AUTOMATION from '@/constants/automation.js';
import AutomationHeader from "./AutomationHeader.vue";
import AutomationWarnings from "./AutomationWarnings"
export default {
  name: "CorporateTemplate",
  data() {
    return {
      setupModal: [],
      panel: true,
      isStatesLoading: true,
    };
  },
  props: {
    automationInfo:
    {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    AutomationAccordian,
    AutomationHeader,
    AutomationWarnings
  },
  computed: {
    ...mapGetters({
      workflows: "automationStore/getAllWorkflows"
    }),
  },
  async created() {
    this.AUTOMATION = AUTOMATION;
    await this.loadAutomations();
    this.loadMissingActions();
  },
  methods: {
    ...mapActions({
      fetchAllWorkflows: 'automationStore/fetchAllWorkflows',
      fetchAllMissingActions: 'automationStore/fetchAllMissingActions'
    }),
    async loadAutomations(){
      if(this.automationInfo && this.automationInfo?.id){
        await this.fetchAllWorkflows ({
          automation_type_id: this.automationInfo.id
        });
        this.isStatesLoading = false;
      }
    },
    loadMissingActions(){
      if(this.automationInfo && this.automationInfo?.id){
        this.fetchAllMissingActions({automation_type_id: this.automationInfo.id,property_id: ''});
      }
    }
  },
  watch: {
    async automationInfo(info){
      this.isStatesLoading = true;
      if(info && info?.id){
        await this.loadAutomations();
        this.loadMissingActions();
      }
    }
  }

};
</script>

<style lang="scss" scoped></style>
